<template>
<div>
    <h1>Prix</h1>

      <h4>Forfait Accueil</h4>
      <SelectButton v-model="baseSelection" :options="accueilOptions" optionLabel="name" />
      <h4>Forfait Coupe</h4>
      <SelectButton v-model="coupeSelection" :options="coupeOptions" optionLabel="name" />
      <h4>Forfait Brushing</h4>
      <SelectButton v-model="brushingSelection" :options="brushingOptions" optionLabel="name" />
      <h4>Forfait Coloration</h4>
      <SelectButton v-model="colorationSelection" :options="colorationOptions" optionLabel="name" />
      <h4>Forfait Mèches</h4>
      <SelectButton v-model="mechesSelection" :options="mechesOptions" optionLabel="name" />
      <h4>Forfait Balayage</h4>
      <SelectButton v-model="barbeSelection" :options="barbeOptions" optionLabel="name" />
      <h4>Forfait Lissage</h4>
      <SelectButton v-model="lissageSelection" :options="lissageOptions" optionLabel="name" />
      <h4>Forfait Permanente</h4>
      <SelectButton v-model="permanenteSelection" :options="permanenteOptions" optionLabel="name" />
      <h4>Forfait Gloss</h4>
      <SelectButton v-model="glossSelection" :options="glossOptions" optionLabel="name" />
      <h4>Autres services</h4>
      <div class="p-inputgroup">
      <InputText id="serviceAmount" v-model="otherServices" type="integer"/>
      <span class="p-inputgroup-addon">CHF</span>
      </div>
      <h4>Réduction</h4>
      <SelectButton v-model="reductionSelection" :options="reductionOptions" optionLabel="name" />

      <h1>Total : {{ total }} CHF</h1>


      <InputButton class="p-button" @click="clear">Effacer</InputButton>
      <InputButton class="p-button-success" @click="assignToAppointment">Facturer</InputButton>

      <DialogView v-model:visible="listApps">
        <GridLoader v-if="openAppointmentsLoading" class="loader"></GridLoader>
        <AppointmentListView :listItemName="itemName" :appointments="openAppointments" :lightVersion="true" :servicePrice="total" @app-updated="goToClient()"/>
      </DialogView>

</div>
</template>

<style scoped>
  .p-button {
      margin-left: 0.5em;
   }

   .p-inputgroup {
    margin:auto;
      width:50%;
   }
</style>

<script setup>

import {ref, computed} from 'vue';
import axios from 'axios'
import { authHeader } from '../helpers/authHeader.js';

import AppointmentListView from './AppointmentListView.vue';

import { useRouter } from 'vue-router';

const router = useRouter();
const itemName = "rendez-vous ouvert";
const listApps = ref(false);

const openAppointmentsLoading = ref(false);
const openAppointments = ref([]);

const otherServices = ref(0);

const reductionSelection = ref({name: 'Non'});
const baseSelection = ref({name:'Oui (5 CHF)', value:5});
const coupeSelection = ref({name: 'Non', value:0});
const brushingSelection = ref({name: 'Non', value:0});
const colorationSelection = ref({name: 'Non', value:0});
const mechesSelection = ref({name: 'Non', value:0});
const barbeSelection = ref({name: 'Non', value:0});
const lissageSelection = ref({name: 'Non', value:0});
const permanenteSelection = ref({name: 'Non', value:0});
const glossSelection = ref({name: 'Non', value:0});

const total = computed(() => {
  const customAmount = parseFloat(otherServices.value) ? parseFloat(otherServices.value) : 0;
  const amount =  baseSelection.value.value
       + coupeSelection.value.value
       + brushingSelection.value.value
       + colorationSelection.value.value
       + mechesSelection.value.value
       + barbeSelection.value.value
       + lissageSelection.value.value
       + permanenteSelection.value.value
       + glossSelection.value.value
        + customAmount;

  const finalAmount = reductionSelection.value.name === 'Non' ? amount : amount * 0.9;
  return (Math.ceil(finalAmount*20)/20).toFixed(2);
})


const reductionOptions = ref([{name: 'Non'}, {name: '10 %'}]);

const accueilOptions = ref([
  {name:'Oui (5 CHF)', value:5},
  {name: 'Non', value:0}]);


const coupeOptions = ref([
  {name: 'Non', value:0},
  {name: 'XS (20 CHF)', value:20},
  {name: 'S (25 CHF)', value:25},
  {name: 'M (45 CHF)', value:45},
  {name: 'L (50 CHF)', value:50},
  {name: 'XL (50 CHF)', value:50},
]);

const brushingOptions = ref([
  {name: 'Non', value:0},
  {name: 'XS (10 CHF)', value:10},
  {name: 'S (15 CHF)', value:15},
  {name: 'M (20 CHF)', value:20},
  {name: 'L (30 CHF)', value:30},
  {name: 'XL (40 CHF)', value:40},
]);


const colorationOptions = ref([
  {name: 'Non', value:0},
  {name: 'XS (67 CHF)', value:67},
  {name: 'S (72 CHF)', value:72},
  {name: 'M (94 CHF)', value:94},
  {name: 'L (119 CHF)', value:119},
  {name: 'XL (140 CHF)', value:140},
]);

const mechesOptions = ref([
  {name: 'Non', value:0},
  {name: 'XS (72 CHF)', value:72},
  {name: 'S (78 CHF)', value:78},
  {name: 'M (85 CHF)', value:85},
  {name: 'L (125 CHF)', value:125},
  {name: 'XL (145 CHF)', value:145},
]);

const barbeOptions = ref([
  {name: 'Non', value:0},
  {name: 'XS (20 CHF)', value:20},
  {name: 'S (20 CHF)', value:20},
  {name: 'M (20 CHF)', value:20},
  {name: 'L (25 CHF)', value:25},
  {name: 'XL (25 CHF)', value:25},
]);

const lissageOptions = ref([
  {name: 'Non', value:0},
  {name: 'S (112 CHF)', value:112},
  {name: 'M (148 CHF)', value:148},
  {name: 'L (170 CHF)', value:170},
  {name: 'XL (180 CHF)', value:180},
]);

const permanenteOptions = ref([
  {name: 'Non', value:0},
  {name: 'S (90 CHF)', value:90},
  {name: 'M (95 CHF)', value:95},
  {name: 'L (110 CHF)', value:110},
  {name: 'XL (135 CHF)', value:135},
]);

const glossOptions = ref([
  {name: 'Non', value:0},
  {name: 'XS (5 CHF)', value:5},
  {name: 'S (5 CHF)', value:5},
  {name: 'M (10 CHF)', value:10},
  {name: 'L (15 CHF)', value:15},
  {name: 'XL (20 CHF)', value:20},
]);

function clear() {
  baseSelection.value = {name:'Oui (5 CHF)', value:5};
  coupeSelection.value = {name: 'Non', value:0};
  brushingSelection.value = {name: 'Non', value:0};
  colorationSelection.value = {name: 'Non', value:0};
  mechesSelection.value = {name: 'Non', value:0};
  barbeSelection.value = {name: 'Non', value:0};
  lissageSelection.value = {name: 'Non', value:0};
  permanenteSelection.value = {name: 'Non', value:0};
  glossSelection.value = {name: 'Non', value:0};
}

function goToClient() {
          router.push('/clients');
}


function assignToAppointment() {
  loadOpenAppointments();
  listApps.value = true;
}

function loadOpenAppointments()
{
  openAppointmentsLoading.value = true;
    openAppointments.value = [];

      var options = {
        "headers" :  authHeader()
      };

      axios
      .get(`${process.env.VUE_APP_API_URL}/Appointments/getOpen`, options)
      .then(response => {
          openAppointments.value = response.data;
          openAppointmentsLoading.value = false;
      });
}


</script>
