<template>
  <div :class="containerClass" @click="onWrapperClick">
    <div class="layout-sidebar" @click="onSidebarClick">
      <AppMenu :model="menu" />
    </div>

    <div class="mobileMenu" style="width:100%;margin-right: auto; margin-left: auto;">
        <i class="pi pi-home" style="font-size: 3rem; margin-right:2%;" @click="goMobileMenu('/')"></i>
        <i class="pi pi-users" style="font-size: 3rem; margin-right:2%;" @click="goMobileMenu('/clients')"></i>
        <i class="pi pi-calendar" style="font-size: 3rem; margin-right:2%;" @click="goMobileMenu('/agenda')"></i>
        <i class="pi pi-money-bill" style="font-size: 3rem; margin-right:2%;" @click="goMobileMenu('/price')"></i>
        <i class="pi pi-chart-line" style="font-size: 3rem; margin-right:2%;" @click="goMobileMenu('/reporting')"></i>
        <i class="pi pi-cog" style="font-size: 3rem; margin-right:2%;"  @click="goMobileMenu('/settings')"></i>

    </div>

    <div class="layout-main-container">
      <div class="layout-main">
        <router-view />
      </div>
    </div>

  </div>
</template>


<script>
import { ref, computed, onMounted } from 'vue';
import AppMenu from './AppMenu.vue';
import { useRouter } from 'vue-router';






export default({
  components: {
    AppMenu
  },
  setup() {

const router = useRouter();

onMounted(() => {
   
document.onkeydown = function (e) {
  if (
    (!e.ctrlKey && !e.metaKey) ||
    e.key === "Meta" ||
    e.key === "Control"
    ) {
    return;
  }

if(e.key === "k")
{
  router.push('/');
}
 
};

 });

function goMobileMenu(route){
  router.push(route);
}



    const menu = ref([
                {
                    items: [{label: 'Dashboard', icon: 'pi pi-fw pi-home', to: '/'},
                    {label: 'Clients', icon: 'pi pi-fw pi-users', to: '/clients'},
                    {label: 'Agenda', icon: 'pi pi-fw pi-calendar', to: '/agenda'},
                    {label: 'Prix', icon : 'pi pi-fw pi-money-bill', to: '/price'},
                    {label: 'Rapports', icon: 'pi pi-fw pi-chart-line', to: '/reporting'},
                    {label: 'Réglages', icon: 'pi pi-fw pi-cog', to: '/settings'}
                    ]
                }
        ]);


    const containerClass = computed(() => {
      return ['layout-wrapper', {
                'layout-static': 'static',
                'layout-mobile-sidebar-active': false,
				'p-input-filled': 'filled',
				'p-ripple-disabled': false
    }]});



      const toggle = (event) => {
            menu.value.toggle(event);
        };
     

        return {  menu, toggle, containerClass, goMobileMenu}
  }
});




</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>

<style lang="scss">
@import './App.scss';
</style>
