<template>
<div class="login">
     <DialogView v-model:visible="loginError" style="width:40%; text-align:center" :keepInViewport="true">
    <h2>Erreur</h2>
    <div style="margin-top 5%; margin-bottom:5%;">{{ errorMessage }}<br/></div>
    <InputButton @click="loginError=false">OK</InputButton>
    </DialogView>
 <CardView style="margin-bottom:10px; border-style: solid;border-color:black;" >

        <template #header>
            <h1>Login</h1>
        </template>
        <template #content>
            <div class="p-fluid logindata grid">
                    <div class="field col-12">
                        <label for="username">Utilisateur</label>
                        <InputText id="username" v-model="username" @keyup.enter="tryLogin" :class="{'p-invalid': validationErrors.username && submitted}" />
                        <small v-show="validationErrors.username && submitted" class="p-error">Merci d'indiquer votre nom d'utilisateur</small>
                    </div>
                    <div class="field col-12">
                        <label for="password">Mot de passe</label>
                        <InputPassword id="password" v-model="password" @keyup.enter="tryLogin" :class="{'p-invalid': validationErrors.password && submitted}" toggleMask :feedback="false" />
                        <small v-show="validationErrors.password && submitted" class="p-error">Merci d'indiquer votre mot de passe</small>
                    </div>
            </div>
            </template>
            <template #footer>
                 <InputButton icon="pi pi-key" label="Login" class="p-button-outlined p-button-secondary" @click="tryLogin"/>
            </template>
        </CardView>
</div>
</template>

<style scoped>

   .login {
       margin-top:5%;
       width: 55%;
       margin-right:auto;
       margin-left:auto;
   }

   .logindata {
       width:95%;
       margin-right:auto;
       margin-left:auto;
   }
</style>

<script setup>

import { ref  } from 'vue';
import axios from 'axios';
import { useRouter } from 'vue-router';


const router = useRouter()


const validationErrors = ref({});

const username = ref("");
const password = ref("");

const loginError = ref(false);
const errorMessage = ref("");

function tryLogin()
{
     var body = {
        "username": username.value,
        "password" : password.value
    }
    
    var json = JSON.stringify(body);
    const options = {
        headers: { 'content-type': 'application/json' },
    };

    axios
    .post(`${process.env.VUE_APP_API_URL}/Users/authenticate`, json, options)
    .then(response => {
            var user = response.data;
            console.log(user);
            console.log(user.token);
            // login successful if there's a jwt token in the response
            if (user.token) {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('user', JSON.stringify(user));
                router.push({path: '/agenda'});
            }
    })
    .catch((error) => {
            console.log(error);
            loginError.value = true;
            if(error.response.data.message.includes("incorrect"))
            {
                errorMessage.value = "Mot de passe et/ou utilisateur incorrect";
            }
            else
            {
                errorMessage.value = "Il y a un problème... " + error.response.data.message;
            }
        });
}



</script>
