<template>
<div>
    <h1>Client·e·s</h1>
    <DialogView v-model:visible="displayCalendar" style="width:100%">
      <CalendarView :contextualClient="store.currentClientFile" :appointmentEdit="appEdited" @closeModal="closeModalAndReload"/>
    </DialogView>


    <DialogView v-model:visible="confirmDelete" style="width:60%; text-align:center" :keepInViewport="false">
    <h2>Voulez-vous supprimer le rendez-vous?</h2>
    Les données liés à ce rendez-vous seront supprimées complètement
      <template #footer>
              <InputButton @click="deleteAppointment">Oui</InputButton>
              <InputButton @click="confirmDelete=false">Non</InputButton>
            </template>
    </DialogView>

    <DialogView v-model:visible="mergeOngoing" style="width:60%; text-align:center" :keepInViewport="false">
    <h2>Fusionner ce dossier dans un autre</h2>
    <GridLoader v-if="clientsLoading" class="loader"></GridLoader>
    <div class="grid" v-if="!clientsLoading">
    <div class="col"><h3>Dossier doublon (sera supprimé)</h3>
    Nom : {{client.lastname}}<br/>
    Prénom : {{client.firstname}}<br/>
    Téléphone : {{client.phone}}<br/>
    Adresse : {{client.address}}<br/>
    Commentaire : {{client.comment}}<br/>
    </div>
    <div class="col"><h3>Dossier conservé</h3>
        <AutoComplete ref="searchbar" v-model="selectedClientToMergeInto" :suggestions="filteredClients" @item-select="loadClientData" @complete="searchClient($event)" forceSelection autoHighlight field="name" inputStyle="width: 100%" style="width:30%;"/>
      <InputButton style = "margin-left:1%;" icon="pi pi-times" class="p-button-rounded p-button-secondary" @click="empty"/>
    <div v-if="selectedClientToMergeIntoData">
    Nom : {{selectedClientToMergeIntoData.lastname}}<br/>
    Prénom : {{selectedClientToMergeIntoData.firstname}}<br/>
    Téléphone : {{selectedClientToMergeIntoData.phone}}<br/>
    Adresse : {{selectedClientToMergeIntoData.address}}<br/>
    Commentaire : {{selectedClientToMergeIntoData.comment}}<br/>
    </div>
    </div>   
    </div>
      <template #footer>
              <MessageItem severity="warn" :closable="false">Tous les rendez-vous (anciens et futurs) du dossier supprimé seront déplacés vers le dossier conservé. Les données personnelles du dossier supprimé seront définitivement effacées.</MessageItem>
              <InputButton @click="confirmMerge">Oui</InputButton>
              <InputButton @click="mergeOngoing=false">Non</InputButton>
            </template>
    </DialogView>
    
    
    <GridLoader v-if="clientsLoading" class="loader"></GridLoader>

    <!-- List all clients  -->
    <div v-if="store.currentClientFile == 0" class="allClientsGrid">
      <div class="card">

      <DataTable :value="allClients" selectionMode="single"  @rowSelect="clientSelected" v-model:selection="selectedClient" v-model:filters="filters"
        :globalFilterFields="['name', 'clientNumber']" :paginator="true" :rows="10">
    
                <template #header>
                    <div class="flex justify-content-between">
                        <span class="p-input-icon-left">
                            <i class="pi pi-search" />
                            <InputText v-model="filters.global.value" placeholder="Recherche" />
                        </span>
                    </div>
                </template>
    <DataTableColumn field="name" header="Nom"><template #body="{data}">{{data.name}}</template></DataTableColumn>
    <DataTableColumn field="phone" header="Téléphone"><template #body="{data}">{{data.phone}}</template></DataTableColumn>
    <DataTableColumn field="id" header="Numéro de client"><template #body="{data}">{{data.clientNumber}}</template></DataTableColumn>

</DataTable>
      </div>
    </div>

    <div v-else>
    <div class="grid">

      <div class="col-12 lg:col-12 xl:col-12">
			<div class="card">
			<h4>Dossier client : {{client.firstname}} {{client.lastname}}</h4>
      <InputButton icon="pi pi-times" label="Fermer le dossier" @click="closeClient" style="margin-right:1%"/>
      <InputButton @click="makeAppointment" class="p-button-success" label="Nouveau RDV" icon="pi pi-calendar" iconPos="right" style="margin-right:1%"/>
       <InputButton @click="mergeClient" class="p-button-danger" label="Fusionner" icon="pi pi-directions" iconPos="right" />
      </div>

		</div>

		<div class="col-12 lg:col-6 xl:col-6">

			<div class="card">
            <h3>Données personnelles</h3>
      <GridLoader v-if="clientUpdating" class="loader"></GridLoader>

				<div class="search">
						<label for="lastname">Nom</label>
						<InputText id="lastname" v-model="client.lastname" type="text"/>
						<label for="firstname">Prénom</label>
						<InputText id="firstname" v-model="client.firstname" type="text"/>
						<label for="phone">Téléphone</label>
						<InputMask id="phone" mask="999/999.99.99" unmask v-model="client.phone" type="text"/>
						<label for="adress">Adresse</label>
						<InputText id="adress" v-model="client.address" type="text"/>
            <label for="clientComment">Commentaire</label>
            <TextArea id="clientComment" v-model="client.comment" type="text" />
				</div>
			<InputButton @click="saveClient" class="p-button-success" label="Sauvegarder" icon="pi pi-save" iconPos="right" />


		</div>
      </div>
    <div class="col-12 lg:col-6 xl:col-6">

			<div class="card">
      <h3>Prochains rendez-vous</h3>
      <h6 v-if="clientNextAppointments.length == 0">Aucun !</h6>
      <GridLoader v-if="nextAppointmentsLoading" class="loader"></GridLoader>

		<CardView style="background:#4ABDAC; margin-bottom:10px; border-style: solid;border-color:black;" v-for="app in clientNextAppointments" :key="app">
	<template #title>
        {{getNiceDate(app.start)}} à {{getNiceTime(app.start)}}
  </template>
  <template #content>
        <b>Durée</b> : {{writeMinutes(app.duration)}}<br/>
        <b>Détails</b> : {{app.details}}
  </template>
  <template #footer>
        <InputButton v-if="!app.validated" icon="pi pi-check" label="Marquer comme vu" class=" p-button-secondary" @click="validate(app.appid)"/>
        <InputButton icon="pi pi-calendar" label="Voir dans l'agenda" class="p-button-secondary" style="margin-left: .5em" @click="goToAgenda(app.start)" />
        <InputButton icon="pi pi-pencil" label="Déplacer" class="p-button-secondary" style="margin-left: .5em" @click="edit(app.appid)" />
        <InputButton icon="pi pi-trash" label="Supprimer" class="p-button-danger" style="margin-left: .5em" @click="askDelete(app.appid)" />


  </template>
  </CardView>

      </div>

		</div>

 <div class="col-12 lg:col-12 xl:col-12">
  <div class="card">
    <h3>Tous les rendez-vous</h3>
    <AppointmentListView :appointments="appointments" :listItemName="itemName" @app-updated="loadClient()"/>
    </div>
 </div>
      </div>
    </div>
    </div>

</template>


<style scoped>
  .search {
	width:100%;
	margin-right: auto;
	margin-left: auto;
    display:grid;
    grid-template-columns: max-content 80%;
    grid-gap:5px;
	margin-bottom: 1%;
}
.search label       { text-align:right; }
.search label:after { content: ":"; }

::v-deep(.row-notclosed) {
  background-color:salmon !important;
}


</style>

<script setup>
import { ref, onMounted } from 'vue';
import axios from 'axios'
import { authHeader } from '../helpers/authHeader.js';
import { useMainStore } from '../store';
import { getNiceDate, getNiceTime, writeMinutes} from  '../dateUtils.js';
import { useRouter } from 'vue-router';
import CalendarView from './CalendarView.vue';
import AppointmentListView from './AppointmentListView.vue';
import {FilterMatchMode} from 'primevue/api';

const mergeOngoing = ref(false);

const itemName = "rendez-vous pour ce·tte client·e";

const displayCalendar = ref(false);

const nextAppointmentsLoading = ref(false);
const clientNextAppointments = ref([]);

const appEdited = ref(null);

const appsLoading = ref(false);
const appointments = ref([]);

const clientLoading = ref(false);
const client = ref({firstname:"", lastname:"", phone:"", address:"", comment: ""});

const store = useMainStore();
const router = useRouter();

const clientUpdating = ref(false);

const confirmDelete = ref(false);
const appToDelete = ref(null);

const allClients = ref([]);
const clientsLoading = ref(false);

const selectedClientToMergeInto = ref(null);
const selectedClientToMergeIntoData = ref(null);

const selectedClient = ref(null);


 const filters = ref({global: {value: null, matchMode: FilterMatchMode.CONTAINS}});
        

onMounted(() => {
   if(store.currentClientFile != 0) {
    loadClient();
   }
   else 
   {
    loadClients();
   }

   

 });

 


function loadClients()
{
    console.log("loading all clients ! ");
      var options = {
        "headers" :  authHeader()
      };
    clientsLoading.value = true;

axios
      .get(`${process.env.VUE_APP_API_URL}/Clients`, options)
      .then(response => {
          allClients.value = response.data;
          clientsLoading.value = false;
 });
}

function clientSelected()
{
  store.$patch((state) => {
              state.currentClientFile = selectedClient.value.clientid;
          });
          loadClient();
}


function confirmMerge()
{
      clientsLoading.value = false;

      var options = {
        "headers" :  authHeader()
      };

          var json = JSON.stringify({});


  axios
      .post(`${process.env.VUE_APP_API_URL}/Clients/merge?mergeClientId=${store.currentClientFile}&keepClientId=${selectedClientToMergeInto.value.clientid}`, json, options)
      .then(() => {
          clientsLoading.value = false;
          store.$patch((state) => {
              state.currentClientFile = selectedClientToMergeInto.value.clientid;
          });
          mergeOngoing.value = false;
          loadClient();
 });
}

function loadClientData() 
{
      clientsLoading.value = true;

      var options = {
        "headers" :  authHeader()
      };

  axios
      .get(`${process.env.VUE_APP_API_URL}/Clients/getById?clientId=${selectedClientToMergeInto.value.clientid}`, options)
      .then(response => {
          clientsLoading.value = false;
          selectedClientToMergeIntoData.value = response.data;
 });

}

function askDelete(id)
{
  appToDelete.value = id;
  confirmDelete.value = true;
}

function mergeClient()
{
  mergeOngoing.value = true;
  clientsLoading.value = true;

     var options = {
        "headers" :  authHeader()
      };

axios
      .get(`${process.env.VUE_APP_API_URL}/Clients`, options)
      .then(response => {
          clientsLoading.value = false;
          allClients.value = response.data;

 });

}

const filteredClients = ref([]);


function empty() {
  filteredClients.value = [];
}


function searchClient(event)
{       
        if (!event.query.trim().length) {
                    filteredClients.value = [...allClients.value].filter(c => c.clientid != store.currentClientFile);
                }
                else {
                    filteredClients.value = allClients.value.filter((client) => {
                        return client.name.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "").includes(event.query.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, ""));
                    }).filter(c => c.clientid != store.currentClientFile);
                }

}




function deleteAppointment()
{

  var options = {
    "headers" :  authHeader()
      };

            var json = JSON.stringify({});


    axios
      .post(`${process.env.VUE_APP_API_URL}/Appointments/deleteappointment?appid=${appToDelete.value}`, json, options)
      .then(() => {
        confirmDelete.value = false;
      appToDelete.value = null;
        loadNextAppointments();
        loadClient();
      })
      .catch(reason => {
          console.log(reason);
          alert("error");
      });
}


function goToAgenda(date)
{          
  store.$patch((state) => {
              state.loadDate = date;
  });

    router.push('agenda');
}

function loadNextAppointments()
{
    nextAppointmentsLoading.value = true;
    clientNextAppointments.value = [];

      var options = {
        "headers" :  authHeader()
      };

      axios
      .get(`${process.env.VUE_APP_API_URL}/Appointments/getForClient?clientId=${store.currentClientFile}&onlyFuture=true`, options)
      .then(response => {
          clientNextAppointments.value = response.data;
          nextAppointmentsLoading.value = false;
      });
}

function closeModalAndReload()
{
  displayCalendar.value = false;
  loadNextAppointments();
  loadClient();
}

function makeAppointment()
{
  appEdited.value = null;
  displayCalendar.value = true;
}

function closeClient()
{
  store.$patch((state) => {
              state.currentClientFile = 0
  });
  loadClients();
}

function edit(id)
{
  appEdited.value = id;
  displayCalendar.value = true;
}

function validate(appid)
{
      var options = {
        "headers" :  authHeader()
      };
    var json = JSON.stringify({});
        axios
      .post(`${process.env.VUE_APP_API_URL}/Appointments/validate?appid=${appid}`, json, options)
      .then( () => {
          loadNextAppointments();
      }
      ).catch(reason => {
          console.log(reason);
          alert("error");
      });
}



function loadClient()
{

    appsLoading.value = true;
    clientLoading.value = true;

      var options = {
        "headers" :  authHeader()
      };


    axios
      .get(`${process.env.VUE_APP_API_URL}/Appointments/getForClient?clientId=${store.currentClientFile}`, options)
      .then(response => {
          appsLoading.value = false;
          appointments.value = response.data;
 });

  axios
      .get(`${process.env.VUE_APP_API_URL}/Clients/getById?clientId=${store.currentClientFile}`, options)
      .then(response => {
          clientLoading.value = false;
          client.value = response.data;
 });

  loadNextAppointments();
}


function saveClient()
{
    var clientData = client.value;

    var json = JSON.stringify(clientData);

     var options = {
        "headers" :  authHeader(true)
      };


        clientUpdating.value = true;

        axios
      .post(`${process.env.VUE_APP_API_URL}/Clients?clientId=${store.currentClientFile}`, json, options)
      .then(() => {
          clientUpdating.value = false;
      })
      .catch(reason => {
          console.log(reason);
          alert("error");
      });
}


</script>


