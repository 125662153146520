import { defineStore } from 'pinia'
import { useStorage } from '@vueuse/core'





export const useMainStore = defineStore({
  id: 'mainmanager',
  state: () => ({
    currentClientFile : useStorage("currentClientFile", "0"),
    loadDate : useStorage("loadDate", '')
  }),
  getters: {
    
    
    
  }
});