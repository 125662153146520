
function getMondayFromDate(date) {
    const first = date.getDate() - date.getDay() + 1;
  
    const monday = new Date(date.setDate(first));
    return monday;
  }
  
  
  function getSaturdayFromDate(date) {
    const first = date.getDate() - date.getDay() + 5;
  
    const sat = new Date(date.setDate(first));
    return sat;
  }



  function getFrom(date) {
    const monday = getMondayFromDate(date);
    return `${monday.getFullYear()}-${("0" + (monday.getMonth() + 1)).slice(-2)}-${("0" + monday.getDate()).slice(-2)}`;
 }
 
 function getTo(date) {
   const sat = getSaturdayFromDate(date);
    return `${sat.getFullYear()}-${("0" + (sat.getMonth() + 1)).slice(-2)}-${("0" + sat.getDate()).slice(-2)}`
 }

 function getTime(time) {
    return "T"+time+":00"
 }

 function getDate(date) {
  return `${date.getFullYear()}-${("0" + (date.getMonth() + 1)).slice(-2)}-${("0" + date.getDate()).slice(-2)}`
 }

 function getNiceTime(datestring)
 {
    var time = datestring.substring(11);
    return time.replace(":", "h");
 }

 function getNiceDate(datestring) {
      var day = datestring.substring(0,10);

      var daysplit = day.split("-");


      const options = {
        dateStyle: 'long'
      }
      var date = new Date( daysplit[0], daysplit[1]-1, daysplit[2]);

      return date.toLocaleString('fr-CH', options);
 }


 function formatDate(dateString) {
  const date = new Date(dateString);
      // Then specify how you want your dates to be formatted
  return new Intl.DateTimeFormat('default', {dateStyle: 'long'}).format(date);
}


 function writeMinutes(min) {
  var hours =  Math.floor(min/60);
  var minutes = min % 60;

  if(hours > 0 && minutes < 10)
  {
      return `${hours}h0${minutes}`
  }
  if(hours > 0)
  {
      return `${hours}h${minutes}`
  }
  return `${minutes} minutes`
}

 
 
 
 
module.exports =  {getFrom, getTo, getTime, getDate, getNiceDate, getNiceTime, writeMinutes, formatDate};

 