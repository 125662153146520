<template>
<div>
    <h1>Réglages</h1>

        <CardView style="margin-bottom:10px; border-style: solid;border-color:black;" v-if="!settingsLoading" >

        <template #header>
            <h2>Site de rendez-vous en ligne</h2>
        </template>
        <template #content>
                <GridLoader v-if="settingsLoading" class="loader"></GridLoader>
            <div class="p-fluid clientdata grid" >
            <div v-for="setting in settings" class="field col-4" style="margin-left:auto; margin-right: auto;" :key="setting.SettingName">
            <label :for="setting.SettingName">{{setting.SettingName}}</label>
            <InputText :id="setting.SettingName" :placeholder="setting.SettingValue" v-model="setting.SettingValue" @keyup.enter="nextPage()"/>
            </div>

            </div>
            </template>
            <template #footer>
                 <InputButton icon="pi pi-save" label="Sauvegarder" class="p-button-outlined p-button-secondary" @click="saveSettings"/>
            </template>
        </CardView>
</div>
</template>

<style scoped>
   
</style>

<script setup>
import { ref, onMounted} from 'vue';


import axios from 'axios'
import { authHeader } from '../helpers/authHeader.js';

const settingsLoading = ref(false);


const settings = ref({});


onMounted(() => {
    loadSettings();
   });


function loadSettings() {
    settingsLoading.value = true;

      var options = {
        "headers" :  authHeader()
      };

  axios
      .get(`${process.env.VUE_APP_API_URL}/Settings`, options)
      .then(response => {
          settingsLoading.value = false;
          settings.value = response.data;
 });
}

function saveSettings() {
    var settingsData = settings.value[0];

    var json = JSON.stringify(settingsData);

     var options = {
        "headers" :  authHeader(true)
      };

        console.log(settingsData);

        settingsLoading.value = true;

        axios
      .post(`${process.env.VUE_APP_API_URL}/Settings?settingId=${settingsData.SettingId}`, json, options)
      .then(() => {
          settingsLoading.value = false;
      })
      .catch(reason => {
          console.log(reason);
          alert("error");
      });
}


</script>
