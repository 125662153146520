<template>
    <img src="../assets/iroquoise_logo_rvb.png" style="width:200px; margin-bottom:2%;"/>

	<div class="grid">
		<div class="col-12 lg:col-12 xl:col-12">
			<div class="card">
      <GridLoader v-if="clientsLoading" class="loader"></GridLoader>
      <div v-if="!clientsLoading">
			<h4>Recherche rapide</h4>
      <AutoComplete v-cloak ref="searchbar" v-model="selectedClient" :suggestions="filteredClients" @item-select="openClient"	@complete="searchClient($event)" forceSelection autoHighlight field="name" inputStyle="width: 100%" style="width:30%;"/>
      <InputButton style = "margin-left:1%;" icon="pi pi-times" class="p-button-rounded p-button-secondary" @click="empty"/>
      </div>
      </div>

		</div>
		
	<div class="col-12 xl:col-6" style="text-align: left !important;">
		<div class="card">
			<h5>Rendez-vous  du jour</h5>
      <h6 v-if="upcomingAppointments.length == 0">Aucun rendez-vous aujourd'hui</h6>
        <GridLoader v-if="upcomingLoading" class="loader"></GridLoader>
        <TimeLine :value="upcomingAppointments">
                <template #opposite="slotProps">
                   <i style="font-size:large; margin-left:1%; margin-right:1%;" class="pi pi-clock"></i><a :class="{past : slotProps.item.past}" style="font-size:large;">{{getNiceTime(slotProps.item.start)}}</a>
                </template>
                <template #content="slotProps">
                    <h5><a :class="{past : slotProps.item.past}" tyle="cursor:pointer" @click="goToClient(slotProps.item.clientid)">{{slotProps.item.name}}</a></h5>
                </template>
          </TimeLine>
		</div>
		
	</div>

	<div class="col-12 xl:col-6" style="text-align: left !important;">
	<div class="card">
			<h5>Nouveaux rendez-vous</h5>
      <h6 v-if="upcomingAppointments.length == 0">Aucun rendez-vous à valider</h6>

  <GridLoader v-if="newAppLoading" class="loader"></GridLoader>
  <CardView style="background:#FFCF43; margin-bottom:10px; border-style: solid;border-color:black;" v-for="app in newAppointments" :key="app">
	<template #title>
        {{app.name}}
  </template>
  <template #content>
        <b>Date et heure</b> : {{getNiceDate(app.start)}} à {{getTime(app.start)}}<br/>
        <b>Durée</b> : {{writeMinutes(app.duration)}}<br/>
        <b>Détails</b> : {{app.details}}
  </template>
  <template #footer>
        <InputButton icon="pi pi-check" label="Marquer comme vu" class="p-button-outlined p-button-secondary" @click="validate(app.appid)"/>
        <InputButton icon="pi pi-calendar" label="Voir dans l'agenda" class="p-button-outlined p-button-secondary" style="margin-left: .5em" @click="goToAgenda(app.start)" />
        <InputButton icon="pi pi-user" label="Voir client" class="p-button-outlined p-button-secondary" style="margin-left: .5em" @click="goToClient(app.clientid)"/>
  </template>
  </CardView>
	</div>
		
</div>
</div>

<div class="grid">
		<div class="col-12 lg:col-12 xl:col-12">
      <div class="card">
			<h5>Rendez-vous non-clôturés</h5>
      <AppointmentListView :appointments="openAppointments" @app-updated="loadOpenAppointments()"/>
	</div>
		</div>
</div>

</template>

<style scoped>
.past {
  color:grey !important;
}
</style>



<script setup>

import { ref, onMounted  } from 'vue';
import axios from 'axios'
import { useRouter } from 'vue-router';
import { authHeader } from '../helpers/authHeader.js';
import { userService } from '../services/userService.js';
import { getNiceDate, getNiceTime, writeMinutes} from  '../dateUtils.js';



import { useMainStore } from '../store'
import AppointmentListView from './AppointmentListView.vue';




const newAppLoading = ref(false);
const upcomingLoading = ref(false);
const openAppointmentsLoading = ref(false);

const clientsLoading = ref(false);




const store = useMainStore();

const router = useRouter();

const clients = ref([]);


router.beforeEach(async (to, from, next) => {
  if(to.path.startsWith('/clients'))
  {
    empty();
    currentClientFile.value = store.currentClientFile;
  }
  next();
});



const currentClientFile = ref("");

const searchbar = ref(null);

const filteredClients = ref([]);

const selectedClient = ref();


function empty() {
  selectedClient.value = "";
  filteredClients.value = [];
}

function validate(appid)
{
      var options = {
        "headers" :  authHeader()
      };
    var json = JSON.stringify({});
        axios
      .post(`${process.env.VUE_APP_API_URL}/Appointments/validate?appid=${appid}`, json, options)
      .then( () => {
         loadNewAppointments();
      }
      ).catch(reason => {
          console.log(reason);
          alert("error");
      });
}




function goToClient(id) {
  store.$patch((state) => {
              state.currentClientFile = id
          });

          router.push('/clients');

}

function openClient() {
        store.$patch((state) => {
              state.currentClientFile = selectedClient.value.clientid;
          });

        currentClientFile.value = selectedClient.value.clientid;
        router.push('/clients');
}

function searchClient(event)
{
        if (!event.query.trim().length) {
            filteredClients.value = [...clients.value];
        }
        else {
            filteredClients.value = clients.value.filter((client) => {
                return client.name.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "").includes(event.query.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "")) || client.phone.includes(event.query);
            });
        }
}






 onMounted(() => {
    loadNewAppointments();
    loadClients();
    loadUpcoming();
    loadOpenAppointments();
    console.log(searchbar.value.focused);
    searchbar.value.focus();
    console.log(searchbar.value.focused);



 });






const newAppointments = ref([]);
const upcomingAppointments = ref([]);
const openAppointments = ref([]);


function loadNewAppointments()
{
    newAppLoading.value = true;
    newAppointments.value = [];

      var options = {
        "headers" :  authHeader()
      };

      axios
      .get(`${process.env.VUE_APP_API_URL}/Appointments/getNew`, options)
      .then(response => {
          newAppointments.value = response.data;
          newAppLoading.value = false;
      });
}

function loadOpenAppointments()
{
  openAppointmentsLoading.value = true;
    openAppointments.value = [];

      var options = {
        "headers" :  authHeader()
      };

      axios
      .get(`${process.env.VUE_APP_API_URL}/Appointments/getOpen`, options)
      .then(response => {
          openAppointments.value = response.data;
          openAppointmentsLoading.value = false;
      });
}


function goToAgenda(date)
{          
  store.$patch((state) => {
              state.loadDate = date;
  });

    router.push('agenda');
}



function loadUpcoming(){
  upcomingLoading.value = true;
  upcomingAppointments.value = [];


  let today = new Date();
  let tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1);

  var offset = today.getTimezoneOffset()
  today = new Date(today.getTime() - (offset*60*1000))
  today = today.toISOString().split('T')[0];

  offset = tomorrow.getTimezoneOffset()
  tomorrow = new Date(tomorrow.getTime() - (offset*60*1000))
  tomorrow = tomorrow.toISOString().split('T')[0];

  
  console.log(today);
  console.log(tomorrow);

   var options = {
        "headers" :  authHeader()
      };

         axios
            .get(`${process.env.VUE_APP_API_URL}/Appointments/GetUpcoming?from=${today}&to=${tomorrow}`, options)
            .then(response => {
              upcomingAppointments.value = response.data;
              classifyUpcomingAppointments();
              upcomingLoading.value = false;
      }).catch(reason => {
          if(reason.message.includes("401"))
          {
            userService.logout();
            router.push({path: '/login'})

          }
          else
          {
            alert("erreur : " + reason.message);
          }
      });
}

function classifyUpcomingAppointments()
{
  upcomingAppointments.value.forEach(app => {
    
    const date = new Date(app.start)
    if(date < new Date())
    {
        app.past = true;
    }
    else 
    {
      app.past = false;
    }


  })
}




function loadClients()
{
    console.log("loading all clients ! ");
      var options = {
        "headers" :  authHeader()
      };
    clientsLoading.value = true;

axios
      .get(`${process.env.VUE_APP_API_URL}/Clients`, options)
      .then(response => {
          clients.value = response.data;
          clientsLoading.value = false;
 });
}

</script>
