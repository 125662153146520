<template>
<div>
    <h1>Rapports</h1>
        <!-- Button to generate reporting for current week -->
        <SelectButton v-model="reportingRange" :options="rangeOptions" optionLabel="name" @click="changedRange()"/>
        <div v-if="reportingRange.value == 'custom'" class="datePicking">
            <h5>Dates</h5>
            du <CalendarView v-model="reportingStartDate" dateFormat="dd/mm/yy" :showIcon="true"/>
            au <CalendarView v-model="reportingEndDate" dateFormat="dd/mm/yy"  :showIcon="true" />
        </div>
        <div class="datePicking">
        <InputButton label="Générer" @click="generateReporting">Générer</InputButton>
    </div>

    <GridLoader v-if="reportingLoading" class="loader"></GridLoader>
    <div v-if="generated">

    <h3>Rapport du {{ formatDate(reportingStartDate) }} au {{ formatDate(reportingEndDate)  }}</h3>


    <h4>Rendez-vous</h4>

    <div class="grid">
        <div class="col-12 md:col-6 lg:col-4">
            <div class="surface-card shadow-2 p-3 border-round">
                <div class="flex justify-content-between mb-3">
                    <div>
                        <span class="dashboardHeading">Rendez-vous</span>
                        <div class="dashboardData">{{reportingData.appointments}}</div>

                    </div>
                    <div class="flex align-items-center justify-content-center bg-blue-100 border-round" style="width:2.5rem;height:2.5rem">
                        <i class="pi pi-calendar text-blue-500 text-xl"></i>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 md:col-6 lg:col-4">
            <div class="surface-card shadow-2 p-3 border-round">
                <div class="flex justify-content-between mb-3">
                    <div>
                        <span class="dashboardHeading">Clients</span>
                        <div class="dashboardData">{{reportingData.clients}}</div>

                    </div>
                    <div class="flex align-items-center justify-content-center bg-blue-100 border-round" style="width:2.5rem;height:2.5rem">
                        <i class="pi pi-users text-blue-500 text-xl"></i>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 md:col-6 lg:col-4">
            <div class="surface-card shadow-2 p-3 border-round">
                <div class="flex justify-content-between mb-3">
                    <div>
                        <span class="dashboardHeading">Durée moyenne des rendez-vous</span>
                        <div class="dashboardData">{{reportingData.avgDuration}} min</div>

                    </div>
                    <div class="flex align-items-center justify-content-center bg-blue-100 border-round" style="width:2.5rem;height:2.5rem">
                        <i class="pi pi-hourglass text-blue-500 text-xl"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
    

  <h4>Revenus</h4>
    <div class="grid">
        <div class="col-12 md:col-6 lg:col-4">
            <div class="surface-card shadow-2 p-3 border-round">
                <div class="flex justify-content-between mb-3">
                    <div>
                        <span class="dashboardHeading">Revenus des services</span>
                        <div class="dashboardData">{{reportingData.serviceSales}} CHF</div>

                    </div>
                    <div class="flex align-items-center justify-content-center bg-blue-100 border-round" style="width:2.5rem;height:2.5rem">
                        <i class="pi pi-money-bill text-blue-500 text-xl"></i>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 md:col-6 lg:col-4">
            <div class="surface-card shadow-2 p-3 border-round">
                <div class="flex justify-content-between mb-3">
                    <div>
                        <span class="dashboardHeading">Revenus des ventes</span>
                        <div class="dashboardData">{{reportingData.productSales}} CHF</div>
                    </div>
                    <div class="flex align-items-center justify-content-center bg-blue-100 border-round" style="width:2.5rem;height:2.5rem">
                        <i class="pi pi-box text-blue-500 text-xl"></i>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 md:col-6 lg:col-4">
            <div class="surface-card shadow-2 p-3 border-round">
                <div class="flex justify-content-between mb-3">
                    <div>
                        <span class="dashboardHeading">Revenu moyen par heure</span>
                        <div class="dashboardData">{{reportingData.avgRevenuePerHour}} CHF</div>

                    </div>
                    <div class="flex align-items-center justify-content-center bg-blue-100 border-round" style="width:2.5rem;height:2.5rem">
                        <i class="pi pi-calculator text-blue-500 text-xl"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
    

</div>

</div>
</template>

<style scoped>
   .datePicking{
    margin-bottom: 20px;
    margin-top: 20px;
   }

   .dashboardData {
    color: #1a202c;
    font-size: 4rem;
    font-weight: bolder;
   }
   .dashboardHeading {
    color: #0d49c1;
    font-size: 1.5rem;
    font-weight: bolder;
   }
</style>

<script setup>

import { ref } from 'vue';
// import { useMainStore } from '../store'
import { authHeader } from '../helpers/authHeader.js';
import { formatDate, getDate } from '../dateUtils.js';

// const store = useMainStore();

import axios from 'axios'



const reportingLoading = ref(false);
const reportingData = ref([]);

const reportingRange = ref({name: "Cette semaine", value: "week"});
const rangeOptions = [
    {name: "Aujourd'hui", value: "today"},
  {name: "Cette semaine", value: "week"},
  {name: "Ce mois", value: "month"},
  {name: "Cette année", value: "year"},
  {name: "Personnalisé", value: "custom"}
];

const reportingStartDate = ref(new Date());
const reportingEndDate = ref(new Date());

const generated = ref(false);

function changedRange() 
{
    generated.value = false;
}


function generateReporting()
{
    var start = new Date();
    var end = new Date();

    if(reportingRange.value.value == "today")
    {
        start.setHours(0,0,0,0);
        end.setHours(23,59,59,999);
        reportingStartDate.value = start;
        reportingEndDate.value = end;
    }
    else if(reportingRange.value.value == "week")
    {
        start.setDate(start.getDate() - start.getDay());
        end.setDate(end.getDate() - end.getDay() + 6);
        reportingStartDate.value = start;
        reportingEndDate.value = end;
    }
    else if(reportingRange.value.value == "month")
    {
        start.setDate(1);
        end.setDate(1);
        end.setMonth(end.getMonth() + 1);
        end.setDate(end.getDate() - 1);
        
        reportingStartDate.value = start;
        reportingEndDate.value = end;
    
    }
    else if(reportingRange.value.value == "year")
    {
        start.setMonth(0);
        start.setDate(1);
        end.setMonth(11);
        end.setDate(31);
        reportingStartDate.value = start;
        reportingEndDate.value = end;

    }

    console.log("reporting generation"  + reportingStartDate.value + " " + reportingEndDate.value);
    loadReporting(getDate(reportingStartDate.value), getDate(reportingEndDate.value));

}

function loadReporting(start, end)
{
console.log("load reporting");
  reportingLoading.value = true;


      var options = {
        "headers" :  authHeader()
      };



      axios
      .get(`${process.env.VUE_APP_API_URL}/Reporting?from=${start}&to=${end}`, options)
      .then(response => {
          reportingData.value = response.data;
          reportingLoading.value = false;
          generated.value = true;
      });
}



</script>
