<template>
<h6 v-if="props.appointments.length == 0">Aucun {{ props.listItemName }}</h6>
<DataTable v-if="props.appointments.length != 0" :value="props.appointments" :rowClass="rowClass" selectionMode="single" v-model:selection="selectedApp" @rowSelect="rowSelected" >
  <DataTableColumn field="name" header="Nom"></DataTableColumn>
  <DataTableColumn field="start" header="Date"><template #body="{data}">{{getNiceDate(data.start)}} - {{getNiceTime(data.start)}}</template></DataTableColumn>
    <DataTableColumn field="duration" header="Durée"><template #body="{data}">{{writeMinutes(data.duration)}}</template></DataTableColumn>
    <DataTableColumn v-if="!props.lightVersion == true" field="ServiceComments" header="Service"><template #body="{data}">{{data.didNotShowUp === "true" ? "Ne s'est pas pointé" : data.serviceComments}}</template></DataTableColumn>
    <DataTableColumn v-if="!props.lightVersion == true" field="ServiceAmount"  header="Montant Service"><template #body="{data}">{{data.serviceAmount}} CHF</template></DataTableColumn>
    <DataTableColumn v-if="!props.lightVersion == true" field="SalesAmount" header="Montant Ventes"><template #body="{data}">{{data.salesAmount}} CHF</template></DataTableColumn>
</DataTable>

<DialogView v-model:visible="selectedApp">
<div class="search">

<h4>Nom</h4>
           <h5>{{ selectedApp.name }}</h5>
            <h4>Statut du rendez-vous</h4>
            <SelectButton v-model="appointmentStatus" :options="statusOptions" optionLabel="name" />


            <h4>Durée</h4>
						<label for="duration">Durée (minutes)</label>
            <div class="p-inputgroup">

<InputText id="duration" v-model="selectedApp.duration" type="integer"/>
<span class="p-inputgroup-addon">{{writeMinutes(selectedApp.duration)}}</span>
                </div>   

            <h4>Services et facturation</h4>
          
						<label for="serviceComments">Service</label>
						<TextArea id="serviceComments" v-model="selectedApp.serviceComments" type="text"/>
						<label for="serviceAmount">Montant service</label>
                            <div class="p-inputgroup">

						<InputText id="serviceAmount" v-model="selectedApp.serviceAmount" type="integer"/>
            <span class="p-inputgroup-addon">CHF</span>
            </div>

            <label for="salesComments">Ventes</label>
						<TextArea id="salesComments" v-model="selectedApp.salesComments" type="text"/>
						<label for="salesAmount">Montant ventes</label>
            <div class="p-inputgroup">

						<InputText id="salesAmount" v-model="selectedApp.salesAmount" type="integer"/>
            <span class="p-inputgroup-addon">CHF</span>
            </div>

<!-- 
            <h4>Mode de paiement</h4>
            <SelectButton v-model="payementType" :options="payementOptions" optionLabel="name" />
 -->

            
            </div>
            
         <template #footer>
            <InputButton @click="updateAppointment" class="p-button-success">Sauvegarder</InputButton>
            <InputButton @click="selectedApp=null" class="p-button-danger">Annuler</InputButton>
          </template>
</DialogView>
</template>

<style scoped>
   
</style>

<script setup>
import {ref, onMounted, defineProps, defineEmits} from 'vue';


import axios from 'axios'
import { useMainStore } from '../store'
import { authHeader } from '../helpers/authHeader.js';
import { getNiceDate, getNiceTime, writeMinutes} from  '../dateUtils.js';


const props = defineProps(['appointments', 'listItemName', 'lightVersion', 'servicePrice']);
const emit = defineEmits(['appUpdated']);

const selectedApp = ref(null);
// const payementType = ref({});
const appointmentStatus = ref({});

const store = useMainStore();

const rowClass = (data) => {
            return data.closed === "true" ? null : 'row-notclosed';
        };


// const payementOptions = ref([
//   {name:'Cash', value:'cash'},
//   {name: 'Carte', value:'card'},
//   {name:'Twint', value:'twint'}]);

const statusOptions = ref([
  {name:'Ouvert', value:'open'},
  {name: 'No Show', value:'noshow'},
  {name:'Clôturé', value:'closed'}]);


onMounted(() => {
   });


function rowSelected(event) {
  selectedApp.value.serviceAmount = props.servicePrice ? props.servicePrice : selectedApp.value.serviceAmount;


  var closed = event.data.closed;
  var noshow = event.data.didNotShowUp;

  if(props.servicePrice){
    closed = "true";
  }

  if(closed === "true"){
    appointmentStatus.value = {name:'Clôturé', value:'closed'};
  } else if (noshow === "true") {
    appointmentStatus.value = {name:'No Show', value:'noshow'};
  } else {
    appointmentStatus.value = {name:'Ouvert', value:'open'};
  }
 }







function updateAppointment()
{
  console.log('updating');
  var appointmentManagerData = selectedApp.value;

    appointmentManagerData.FlagClosed = appointmentStatus.value.value == "closed" ? "true" : "false";
    appointmentManagerData.FlagDidNotShowUp = appointmentStatus.value.value == "noshow" ? "true" : "false";

 
    var json = JSON.stringify(appointmentManagerData);

     var options = {
        "headers" :  authHeader(true)
      };


        axios
      .post(`${process.env.VUE_APP_API_URL}/Appointments/updateAppManagement?appId=${selectedApp.value.appid}`, json, options)
      .then(() => {
           store.$patch((state) => {
              state.currentClientFile = selectedApp.value.clientid;
           });
           selectedApp.value = null;
           emit('appUpdated');
      })
      .catch(reason => {
          console.log(reason);
          alert("error");
      });
}





</script>
