import { createRouter, createWebHistory } from 'vue-router';
import ClientView from './components/ClientView.vue'
import CalendarView from './components/CalendarView.vue'
import LoginView from './components/LoginView.vue';
import HomeView from './components/HomeView.vue';
import SettingsView from './components/SettingsView.vue'
import ReportingView from './components/ReportingView'
import PriceCalculatorView from './components/PriceCalculatorView'



export const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
        path: '/',
        component: HomeView
        },
    {
        path: "/clients",
        component: ClientView
    },
    {
        path: "/agenda",
        component: CalendarView
    },
    { 
        path: '/agenda/:date',
        component: CalendarView
    },
    {
        path: "/settings",
        component: SettingsView
    },
    {
        path: "/reporting",
        component: ReportingView
    },

    {
        path: "/price",
        component: PriceCalculatorView
    },

    { path: '/login', component: LoginView },
    { path: '/:pathMatch(.*)*', redirect : '/agenda'}
    ]
});


router.beforeEach((to, from, next) => {
    // redirect to login page if not logged in and trying to access a restricted page
    const publicPages = ['/login'];
    const authRequired = !publicPages.includes(to.path);
    const loggedIn = localStorage.getItem('user');
  
    if (authRequired && !loggedIn) {
      return next('/login');
    }
  
    next();
  })


