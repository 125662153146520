<template>
<div>
    <h1>Agenda</h1>
    <DialogView v-model:visible="blocker" style="width:60%; text-align:center" :keepInViewport="false">
    <h2>Voulez-vous supprimer le blocker?</h2>
      <template #footer>
              <InputButton @click="deleteBlocker">Oui</InputButton>
              <InputButton @click="blocker=false">Non</InputButton>
            </template>
    </DialogView>

    <DialogView v-model:visible="display" style="width:60%; text-align:center" :keepInViewport="false">
    <template #header>
		<h2>Nouveau rendez-vous</h2>
    <h3>{{ startTime.toLocaleString('default', {weekday: "long", year: "numeric", month: "long", day: "numeric"}) }}</h3>
	</template>

<div class="grid container">
	<div class="field col-6 md:col-6">
                <label for="timebegin">Début</label>
                <CalendarView :timeOnly="false" id="timebegin" v-model="startTime" :showTime="true" :stepMinute="15"/>
  </div>

      
  <div class="field col-6 md:col-6">
                <label for="timeend">Fin</label>
                <CalendarView :timeOnly="false" id="timeend" v-model="endTime" :showTime="true" :stepMinute="15" />
  </div>
  <small v-show="timeerror && submitted" class="p-error">Merci de vérifier les horaires</small>

  </div>
<div class="grid container" v-if="!contextualClient">

    <div class="col-4 md:col-4">
            <div class="field-radiobutton">
            <RadioButton id="radioappointment" name="Rendez-vous" value="appointment" v-model="apptype" />
            <label for="city1">Rendez-vous</label>
        </div>
        </div>

    <div class="col-4 md:col-4">
        <div class="field-radiobutton">
            <RadioButton id="radioblocker" name="Blocker" value="blocker" v-model="apptype" />
            <label for="city1">Blocker</label>
        </div>
</div>
    <div class="col-4 md:col-4">
         <div class="field-radiobutton">
            <RadioButton id="radioavailability" name="Ouverture" value="availability" v-model="apptype" />
            <label for="city1">Ouverture</label>
        </div>
        </div>
</div>

    <div class="p-fluid clientdata" v-show="apptype=='appointment'" >
                    <div class="field" v-if="!contextualClient">
                        <label for="firstname">Prénom</label>
                        <InputText id="firstname" v-model="firstname" @keyup.enter="nextPage()" :class="{'p-invalid': validationErrors.firstname && submitted}" />
                        <small v-show="validationErrors.firstname && submitted" class="p-error">Merci d'indiquer votre prénom</small>
                    </div>
                    <div class="field" v-if="!contextualClient">
                        <label for="lastname">Nom</label>
                        <InputText id="lastname" v-model="lastname" @keyup.enter="nextPage()" :class="{'p-invalid': validationErrors.lastname && submitted}" />
                        <small v-show="validationErrors.lastname && submitted" class="p-error">Merci d'indiquer votre nom de famille.</small>
                    </div>
                    <div class="field" v-if="!contextualClient">
                        <label for="phone">Numéro de téléphone</label>
                        <InputText id="phone" v-model="phone" @keyup.enter="nextPage()" />
                        <small v-show="validationErrors.phone && submitted" class="p-error">Veuillez entrer un numéro de téléphone valide</small>
                    </div>
                        <div class="field" v-if="!appointmentEdit">
                        <label for="services">Services</label>
                        <InputText id="services" v-model="services" @keyup.enter="nextPage()" :class="{'p-invalid': validationErrors.services && submitted}" />
                        <small v-show="validationErrors.services && submitted" class="p-error">Merci d'indiquer au moins un service</small>
                    </div>
                </div>



    <div class="p-fluid blockerdata" v-show="apptype=='blocker'">
                    <div class="field">
                        <label for="blockerreason">Raison (optionnel)</label>
                        <InputText id="blockerreason" v-model="blockerreason" @keyup.enter="nextPage()" :class="{'p-invalid': validationErrors.blockerreason && submitted}" />
                    </div>
                  
                </div>

            <template #footer>
              <InputButton @click="bookAppointment">OK</InputButton>
              <InputButton @click="display=false">Annuler</InputButton>
            </template>

</DialogView>

    <MessageItem v-if="editedEvent" severity="info" :closable="false">Vous êtes en train de déplacer le RDV de <b>{{editedEvent.title}}</b> actuellement prévu de {{editedEvent.date}} à {{editedEvent.end}}</MessageItem>


    <GridLoader v-if="loading" class="loader"></GridLoader>
    <FullCalendar ref="fullcalendar" :events="events" :options="options" :disabled="loading"/>


   
</div>
</template>

<style scoped>
  label {
    margin-right:2px;
  }

  .loader {
    z-index:1000;
    position: absolute;
    left:50%;
    top:50%;
  }


</style>

<script setup>




import { ref, onBeforeMount, defineProps, defineEmits, computed } from 'vue';
import timeGridPlugin from '@fullcalendar/timegrid';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import axios from 'axios';
import { useRouter } from 'vue-router';
import { getDate, getTime } from  '../dateUtils.js'
import GridLoader from './GridLoader.vue';
import { useMainStore } from '../store'
import { authHeader } from '../helpers/authHeader.js'
import { userService } from '../services/userService.js'


const props = defineProps(['contextualClient', 'appointmentEdit']);
const emit = defineEmits(['closeModal']);


const store = useMainStore();



const loadDate = ref(null);
const loadView = ref('timeGridWeek');

const fullcalendar = ref(null);

const loading = ref(false);

const currentView = ref("");

const startTime = ref(null);
const endTime = ref(null);

const minTime = ref(null);
const maxTime = ref(null);

const apptype = ref("appointment");

const router = useRouter();

const display = ref(false);

const services = ref("");

const firstname = ref("");
const lastname = ref("");
const phone = ref("");


const timeerror = ref(false);

const blockerreason = ref("");

var submitted = ref(false);

const forceload = ref(false);

const blockerToDelete = ref(0);
const blocker = ref(false);



const events = ref([]);
const editedEvent = computed(() => {
  return events.value.find(app => app.appid = props.appointmentEdit)
});

const validationErrors = ref({});


 onBeforeMount(() => {
    if(store.loadDate != '')
    {
      loadView.value = 'timeGridDay';
      loadDate.value = store.loadDate;
              store.$patch((state) => {
              state.loadDate = '';
  });

    }});


function validateForm() {

console.log(props.contextualClient)

  if(apptype.value == "appointment" && props.contextualClient == null){
            if (!firstname.value.trim())
                validationErrors.value['firstname'] = true;
            else
                delete validationErrors.value['firstname'];

            if (!lastname.value.trim())
                validationErrors.value['lastname'] = true;
            else
                delete validationErrors.value['lastname'];

            if (!services.value.trim())
                validationErrors.value['services'] = true;
            else
                delete validationErrors.value['services'];

            var numbers = /^[0-9]+$/;
            if (!phone.value.trim() || !phone.value.trim().match(numbers) || phone.value.trim().length < 8)
                validationErrors.value['phone'] = true;
            else 
                delete validationErrors.value['phone'];

  }

            return !Object.keys(validationErrors.value).length;
}

function deleteBlocker()
{
     var options = {
        "headers" :  authHeader()
      };



            var json = JSON.stringify({});


      axios
      .post(`${process.env.VUE_APP_API_URL}/Appointments/deleteBlocker?blockerid=${blockerToDelete.value}`, json,  options)
      .then(response => {
          console.log(response.status)
          blocker.value = false;
          forceload.value = true;
          emptyForm();
          loadAppointments();
      })
      .catch(reason => {
          if(reason.message.includes("401"))
          {
            blocker.value = false;
            userService.logout();
            router.push({path: '/login'})

          }
          else
          {
            alert("erreur : " + reason.message);
          }
      });
}




function loadAppointments() {
  
  var view = fullcalendar.value.calendar.view
  
  if(currentView.value != view.title || forceload.value)
    {
      forceload.value = false;
      loading.value = true;
      events.value = [];

      currentView.value = view.title;

      var options = {
        "headers" :  authHeader()
      };

      var start = getDate(view.currentStart);
      var end = getDate(view.currentEnd);

        axios
            .get(`${process.env.VUE_APP_API_URL}/Appointments/GetAgenda?from=${start}&to=${end}`, options)
            .then(response => {
              events.value = response.data;
              console.log("edit : " + props.appointmentEdit);
              if(props.appointmentEdit != null)
              {
                console.log(events.value);
                if(props.appointmentEdit != null)
                {
                  events.value = events.value.filter(e => e.appid != props.appointmentEdit);
                }
              }
              console.log("events : " + events.value.length);
              loading.value = false;
      }).catch(reason => {
          if(reason.message.includes("401"))
          {
            userService.logout();
            router.push({path: '/login'})

          }
          else
          {
            alert("erreur : " + reason.message);
          }
      });
    }

}

function openAppointment(info)
{
  var id = info.event.id;
  if(id.length > 0)
  {
    store.$patch((state) => {
              state.currentClientFile = id;
  });


    router.push({path: '/clients'});
  } else if(info.event.backgroundColor != "lightgreen"){
    blockerToDelete.value = info.event.extendedProps.blockerid;
    blocker.value = true;

  }

  
}

function bookAppointment()        
{
  var minutes = -1;
  var formatteddate = "";
  timeerror.value = false;
  submitted.value = true;
   
  console.log(startTime.value);
  console.log(endTime.value);
  




  if(validateForm())
  {
     var options = {
        "headers" :  authHeader(true)
      };


    if(props.appointmentEdit != null)
    {


        minutes = minDiff(startTime.value, endTime.value);
        if(minutes < 0)
        { 
          timeerror.value = true;
          return;
        }

         formatteddate = getDate(startTime.value) + getTime(startTime.value.toTimeString().substring(0,5));


      //we won't book a new one but edit the existing
         axios
      .post(`${process.env.VUE_APP_API_URL}/Appointments/update?appointmentTime=${formatteddate}&duration=${minutes}&appId=${props.appointmentEdit}`, options)
      .then(response => {
          console.log(response.status)
          display.value = false;
          forceload.value = true;
          emit('close-modal');
      })
      .catch(reason => {
          if(reason.message.includes("401"))
          {
            userService.logout();
            router.push({path: '/login'})

          }
          else
          {
            alert("erreur : " + reason.message);
          }
      });


      return;
    }

    if(apptype.value == "appointment" && props.contextualClient == null)
    {
        var clientData = 
        {
          firstname : firstname.value,
          lastname: lastname.value,
          phone: phone.value,
          length: "long",
          thickness: "épais",
          services: [services.value]
        }
        var json = JSON.stringify(clientData);

        minutes = minDiff(startTime.value, endTime.value);
        if(minutes < 0)
        { 
          timeerror.value = true;
          return;
        }

         formatteddate = getDate(startTime.value) + getTime(startTime.value.toTimeString().substring(0,5));
        console.log(formatteddate);

   


        axios
      .post(`${process.env.VUE_APP_API_URL}/Appointments?appointmentTime=${formatteddate}&duration=${minutes}&validated=true`, json, options)
      .then(response => {
          console.log(response.status)
          display.value = false;
          forceload.value = true;
          emptyForm();
          loadAppointments();
      })
      .catch(reason => {
          if(reason.message.includes("401"))
          {
            userService.logout();
            router.push({path: '/login'})

          }
          else
          {
            alert("erreur : " + reason.message);
          }
      });


    }
    else if(props.contextualClient != null)
    {
      console.log(props.contextualClient)
      // this is also an appointment, booked by clientID
       minutes = minDiff(startTime.value, endTime.value);
        if(minutes < 0)
        { 
          timeerror.value = true;
          return;
        }
        clientData = 
        {
          services: [services.value]
        }
         json = JSON.stringify(clientData);


         formatteddate = getDate(startTime.value) + getTime(startTime.value.toTimeString().substring(0,5));

            axios
      .post(`${process.env.VUE_APP_API_URL}/Appointments?clientId=${props.contextualClient}&appointmentTime=${formatteddate}&duration=${minutes}&validated=true`, json, options)
      .then(response => {
          console.log(response.status)
          display.value = false;
          forceload.value = true;
          emit('close-modal');
      })
      .catch(reason => {
          if(reason.message.includes("401"))
          {
            userService.logout();
            router.push({path: '/login'})

          }
          else
          {
            alert("erreur : " + reason.message);
          }
      });



    }
    else if(apptype.value == "blocker")
    {
         minutes = minDiff(startTime.value, endTime.value);
        if(minutes < 0)
        { 
          timeerror.value = true;
          return;
        }

        formatteddate = getDate(startTime.value) + getTime(startTime.value.toTimeString().substring(0,5));
        console.log(formatteddate);


  

        axios
      .post(`${process.env.VUE_APP_API_URL}/Appointments/blocker?appointmentTime=${formatteddate}&duration=${minutes}&reason=${blockerreason.value}`, json, options)
      .then(response => {
          console.log(response.status)
          display.value = false;
          forceload.value = true;
          emptyForm();
          loadAppointments();
      })
      .catch(reason => {
          if(reason.message.includes("401"))
          {
            userService.logout();
            router.push({path: '/login'})

          }
          else
          {
            alert("erreur : " + reason.message);
          }
      });
      display.value = false;
    }
    else if(apptype.value == "availability")
    {

    

  console.log("availability");

          var formattedstart = getDate(startTime.value) + getTime(startTime.value.toTimeString().substring(0,5));
          var formattedend = getDate(endTime.value) + getTime(endTime.value.toTimeString().substring(0,5));




 axios
      .post(`${process.env.VUE_APP_API_URL}/Plannings?start=${formattedstart}&end=${formattedend}`, json, options)
      .then(response => {
          console.log(response.status)
          display.value = false;
          forceload.value = true;
          emptyForm();
          loadAppointments();
      })
     .catch(reason => {
          if(reason.message.includes("401"))
          {
            userService.logout();
            router.push({path: '/login'})

          }
          else
          {
            alert("erreur : " + reason.message);
          }
      });

      display.value = false;
    }
  }
}

function emptyForm()
{
  firstname.value = "";
  lastname.value = "";
  services.value = "";
  phone.value = "";
  blockerreason.value = "";
}

function minDiff(d1, d2)
{
const diffMs = d2.getTime() - d1.getTime();
const diffMins = Math.floor((diffMs / 1000) / 60);
console.log(diffMins)
return diffMins
}

function selectSlots(info)
{
 //compute min time (00:00) and maxtime (23:59)
  minTime.value = new Date(info.start);
  minTime.value.setHours(0,0,0,0);

  maxTime.value = new Date(info.end);
  maxTime.value.setHours(23,59,59,0);


  startTime.value = info.start;
  endTime.value = info.end;

  display.value = true;
}

 
const options = ref({
        plugins: [ timeGridPlugin, dayGridPlugin, interactionPlugin ],
        initialView: loadView,
        initialDate: loadDate,
        firstDay: 1,
        events: events,
        hiddenDays : [0],
        headerToolbar: {
          left: 'prev,next today',
          center: 'title',
          right: 'timeGridWeek,timeGridDay,dayGridMonth'
        }, 
        datesSet: loadAppointments,
        eventClick: openAppointment,
        slotMinTime: '08:00',
        slotMaxTime: '20:00',
        snapDuration : '00:15',
        slotDuration : '00:15',
        locale: 'fr-CH',
        allDaySlot: false,
        nowIndicator: true,
        selectable: true,
        select: selectSlots,
        longPressDelay: 700,
        selectOverlap: function(event) {
    return event.display === 'background';
  }
      });



</script>
